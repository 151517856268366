@import "custom_bootstrap_vars";
@import "blog";
@import "~bootstrap/scss/bootstrap";
@import '~bootstrap-icons/font/bootstrap-icons.css';
@import '~@selectize/selectize/dist/css/selectize.bootstrap5.css';




$enable-negative-margins: true;
// project specific CSS goes here
// Alert colors
$white: #fff;
$mint-green: #d6e9c6;
$black: #000;
$pink: #f2dede;
$dark-pink: #eed3d7;
$red: #b94a48;
// bootstrap alert CSS, translated to the django-standard levels of
// debug, info, success, warning, error
.alert-debug {
    background-color: $white;
    border-color: $mint-green;
    color: $black;
}

.alert-error {
    background-color: $pink;
    border-color: $dark-pink;
    color: $red;
}

html {
    scroll-behavior: smooth;
    display: list-item;
}

.navbar-scroll .nav-link:hover {
    color: #3273dc;
}

.navbar-scroll {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .2);
}

.logo {
    border-radius: 50%;
    float: left;
    height: 2em;
}

.main-content {
    margin-top: 2em;
}

.banner-smanager {
    max-width: 100%;
    width: 25em;
    padding-right: 2em;
}

.banner-text {
    padding-left: 1em;
}

.invalid-feedback {
    font-weight: 300;
}

#div_id_login,
#div_id_password,
#div_id_password1,
#div_id_password2,
#div_id_oldpassword {
    font-weight: bold;
}

.small-text {
    font-size: small;
}

.text-is-warped {
    width: 60vh;
}

#join_us {
    background-color: #fff;
}

.asteriskField {
    display: none;
}

.has-text-primary {
    color: #3273dc !important;
}

.btn.is-fullwidth {
    display: flex;
    width: 100%;
}

.btn.btn-outline-primary:hover {
    background-color: #3273dc;
}

.btn.btn-outline-primary {
    border-color: #3273dc;
    text-decoration: #3273dc;
}

.btn.btn-primary {
    background-color: #3273dc;
}

.vertical-center {
    min-height: 100%;
    /* Fallback for browsers do NOT support vh unit */
    min-height: 100vh;
    /* These two lines are counted as one :-)       */
    display: flex;
    align-items: center;
}

.htmx-indicator {
    opacity: 0;
    display: none;
    transition: opacity 500ms ease-in;
}

.htmx-request.htmx-indicator {
    display: inline-block;
    opacity: 1
}

#up_navbar>.dropdown-margin-right {
    margin-right: 30px;
}


ul {
    list-style: none
}

.section-home {
    padding-top: 1.5rem;
}

html {
    font-size: 16px;
    min-width: 300px;
    overflow-x: hidden;
    overflow-y: scroll;
    text-rendering: optimizeLegibility;
    text-size-adjust: 100%
}

article,
aside,
figure,
footer,
header,
hgroup,
section {
    display: block
}

.navbar {
    background-color: #fff;
    min-height: 3.25rem;
    //position: relative;
    //z-index: 30
}

html,
body,
p,
ol,
ul,
li,
dl,
dt,
dd,
blockquote,
figure,
fieldset,
legend,
textarea,
pre,
iframe,
hr {
    margin: 0;
    padding: 0
}

.footer {
    padding-top: 4rem;
}

.nav .flex-column .nav-underline {
    width: 2rem;
}

.dashboard-card-img {
    width: 40%;
    height: 40%;
}

.aside-nav {
    width: 23rem;
}

.accordion-button:not(.collapsed) {
    background-color: #fff;
}

// card list css
.card {
    box-shadow: 0 20px 27px 0 rgb(0 0 0 / 5%);
}

.avatar-md {
    height: 5rem;
    width: 5rem;
}

.fs-19 {
    font-size: 19px;
}

.primary-link {
    color: #314047;
    -webkit-transition: all .5s ease;
    transition: all .5s ease;
    text-decoration: none;
}

.secondary-link {
  color: #3273dc;
  text-decoration: none;
}

.primary-link:hover {
    color: #3273dc
}

.list-inline li {
    display: inline;
}

.job-list-box .bookmark-icon {
    position: absolute;
    right: 0.05rem;
    top: 0.5rem;
}

.fs-14 {
    font-size: 14px;
}

.bg-soft-secondary {
    background-color: rgba(116, 120, 141, .15)!important;
    color: #74788d!important;
}

// heart animation
.bookmark-icon {
    padding-top: 0.3em;
    height: 50px;
    width: 50px;
    margin: 0 auto;
    cursor: pointer;
}


.navbar-toggler {
    border-color: #fff;
}

.side.sticky-top {
    top: 5em;
    z-index: 0;
}

.submit-btn-end {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}
.submit-btn-start {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-start;
}

.feature {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 4rem;
    width: 4rem;
    font-size: 2rem;
}

.footer-clean {
    padding: 50px 0;
    background-color: #fff;
    color: #4b4c4d;
}

.footer-clean h3 {
    margin-top: 0;
    margin-bottom: 12px;
    font-weight: bold;
    font-size: 16px;
}

.footer-clean ul {
    padding: 0;
    list-style: none;
    line-height: 1.6;
    font-size: 14px;
    margin-bottom: 0;
}

.footer-clean ul a {
    color: inherit;
    text-decoration: none;
    opacity: 0.8;
}

.footer-clean ul a:hover {
    opacity: 1;
}

.footer-clean .item.social {
    text-align: center;
}

@media (max-width:767px) {
    .footer-clean .item {
        text-align: center;
        padding-bottom: 20px;
    }
}

@media (max-width: 768px) {
    .footer-clean .item.social {
        text-align: center;
    }
}

.footer-clean .item.social>a {
    font-size: 24px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    display: inline-block;
    text-align: center;
    margin-left: 10px;
    margin-top: 22px;
    color: inherit;
    opacity: 0.75;
    color: #3273dc;
}

.footer-clean .item.social>a:hover {
    opacity: 0.9;
}

@media (max-width:991px) {
    .footer-clean .item.social>a {
        margin-top: 40px;
    }
}

@media (max-width:767px) {
    .footer-clean .item.social>a {
        margin-top: 10px;
    }
}

.footer-clean .copyright {
    margin-top: 15px;
    margin-bottom: 0;
    font-size: 15px;
    opacity: 0.6;
}


/* Credit to https://epicbootstrap.com/snippets/footer-with-columns */

.round-dot {
    height: 30px;
    width: 30px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
}

.fs-15 {
    font-size: 15px;
}

.fs-13 {
    font-size: 13px;
}

.fs-17 {
    font-size: 17px;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-weight: 600;
    line-height: 1.2;
}

.candidate-contact-details .icon,
.job-overview .icon {
    height: 46px;
    line-height: 46px;
    width: 46px;
    border-radius: 46px;
    text-align: center;
    font-size: 20px;
}

.bg-soft-primary {
    background-color: #eaf1fb!important;
    color: #6f9de6!important;
}

.soft-primary {
  color: #6f9de6!important;
}

.btn-soft-warning {
    background-color: rgba(247, 204, 83, .15)!important;
    border-color: transparent;
    color: #f7cc53!important;
}

.btn-hover {
    /*
    All of the base styles have
    moved to the “CSS” tab above.
  */
    transition: transform 250ms;
}


.btn-hover:hover {
    transform: translateY(-5px);
}


.side-bar-item.nav-link.active-page  {
  color: #3273dc;
  font-weight: 500;

}
.side-bar-item.nav-link {
  color: rgba(var(--bs-emphasis-color-rgb), 0.65);
  text-align: left;
}

.side-bar-item.nav-link:hover {
  color: #3273dc;
  font-weight: 500;

}



// chosen js
// .select2-container--classic .select2-selection--multiple {
//   background-color: white;
//   border: 1px solid #dee2e6;
//   border-radius: 0.375rem;
//   cursor: text;
//   outline: 0;
//   padding-bottom: 5px;
//   padding-right: 5px;
// }

.resume-section-title {
  font-size: 1.25rem;
  position: relative;
  color: #434E5E;
}

.resume-section-title:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1.5px;
  background: #8d9aad;
}

.resume-section-content {
  color: #58677c;
}

.resume-timeline {
  padding-left: 2rem;
}

.resume-timeline:before {
  content: "";
  display: inline-block;
  width: 3px;
  height: 100%;
  background: #6f9de6;
  left: 6px;
  top: 4px;
  position: absolute;
}

.resume-timeline-item:before {
  content: "";
  display: inline-block;
  position: absolute;
  left: -32px;
  top: 3px;
  width: 15px;
  height: 15px;
  border: 4px solid #3273dc;
  background: white;
  border-radius: 50%;
}

.resume-progress {
  height: 0.6rem;
}

.resume-company-name {
  color: #58677c;
  font-size: 0.875rem;
  font-weight: 500;
}

.resume-position-time {
  font-size: 0.875rem;
  color: #aab4c3;
}

.resume-position-title {
  font-size: 1.125rem;
  color: #434E5E;
}

.resume-timeline-item-desc-heading {
  font-size: 1rem;
}

.resume-skills-cat {
  font-size: 1rem;
  color: #434E5E;
}

.resume-skill-name {
  font-size: 0.8125rem;
  color: #434E5E;
}

.badge-light {
  color: #58677c;
  background: #F7F8FA;
}

.resume-degree {
  color: #434E5E;
}

.resume-degree-org {
  font-size: 0.875rem;
}

.resume-degree-time {
  color: #aab4c3;
  font-size: 0.875rem;
}

.resume-award-icon {
  left: 0;
  top: 4px;
  color: #aab4c3;
}

.resume-award-name {
  font-weight: bold;
  color: #434E5E;
}

.resume-award-desc {
  font-size: 0.875rem;
}

.resume-lang-name {
  color: #434E5E;
}

.secondary-text{
  color: #6f9de6;
}

.pg-text-success {
  color: #48c78e !important;
}

.pg-text-info {
  color: #3e8ed0 !important;
}

.bg-primary {
  background-color: #3273dc !important;
}

.accordion-item {
  border: none;
}

// .django-ckeditor-widget {
//   border-radius: 0.375rem;  /* Bootstrap's standard rounded class */
//   overflow: hidden;
// }

// .cke_textarea_inline {
//   border-radius: 0.375rem;
// }

.cke_editable input[type="text"],
.cke_editable textarea,
.cke_editable select {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

/* ... more styles as needed ... */

/* Style the CKEditor toolbar to blend better (optional) */
.cke_inner {
    border: 1px solid #ced4da; /* Example: Match Bootstrap border */
}
.cke_top {
    background-color: #f8f9fa; /* Example: A light background color */
}
