


.main-wrapper {
  //margin-left: 280px;
  background: #fff
}

.main-wrapper .container-single-col {
  max-width: 820px
}

.cta-section .heading {
  font-size: 2rem;
  font-weight: bold
}

body.dark-mode .main-wrapper>.cta-section {
  border-bottom: 1px solid rgba(255,255,255,0.05);
  background: none !important
}

.single-col-max-width {
  max-width: 820px
}

.blog-list .item .title {
  font-size: 1.275rem
}

.blog-list .item .title a {
  color: #292929
}

.blog-list .item .title a:hover {
  color: #292929
}

.blog-list .item .post-thumb {
  border-radius: 2px
}

.blog-list .item .intro {
  font-size: 0.875rem
}

.blog-list .item .more-link {
  font-size: 0.8125rem
}

.meta {
  color: #8f8f8f;
  font-size: 0.8125rem
}

.meta span {
  display: inline-block
}

.meta span a {
  color: #8f8f8f
}

.meta span a:hover {
  color: #4f4f4f
}

.meta span:after {
  content: "";
  display: inline-block;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background: #8f8f8f;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  position: relative;
  top: -3px
}

.meta span:last-child:after {
  display: none
}

.blog-post .title {
  font-weight: bold;
  font-size: 2rem
}

.blog-post .blog-post-body p,.blog-post .blog-post-body li {
  font-size: 1.125rem;
  line-height: 1.6
}

.blog-post .blog-post-body h1,.blog-post .blog-post-body .h1 {
  font-size: 2.125rem
}

.blog-post .blog-post-body h2,.blog-post .blog-post-body .h2 {
  font-size: 2rem
}

.blog-post .blog-post-body h3,.blog-post .blog-post-body .h3 {
  font-size: 1.75rem
}

.blog-post .blog-post-body h4,.blog-post .blog-post-body .h4 {
  font-size: 1.5rem
}

.blog-post .blog-post-body h5,.blog-post .blog-post-body .h5 {
  font-size: 1.25rem
}

.blog-post .blog-post-body h6,.blog-post .blog-post-body .h6 {
  font-size: 1.125rem
}

.blog-post .image-caption {
  color: #8f8f8f;
  font-size: 0.875rem
}

.blog-post .image-caption a {
  color: #8f8f8f;
  text-decoration: underline
}

.blog-post .image-caption a:hover {
  color: #4f4f4f
}

.blog-post .blockquote {
  font-family: Georgia,"Times New Roman",Times,serif;
  border-left: 2px solid #292929;
  font-size: 1.5rem
}

.blog-post .blockquote p {
  font-size: 1.5rem
}

.single-form-max-width {
  max-width: 600px
}


@media (min-width: 768px) {
  .theme-btn-cta {
      width:inherit;
      display: inline-block
  }

  .cta-section .form-inline .form-control {
      width: 360px
  }

  .pricing-table td,.pricing-table th {
      display: table-cell !important
  }
}

@media (max-width: 767.98px) {
  .signup-form .form-group {
      width:100%;
      margin-bottom: 0.5rem
  }

  .signup-form .form-control {
      width: 100%
  }

  .signup-form .btn-primary {
      width: 100%
  }

  .profile-teaser .profile-image {
      max-width: 250px
  }

  .header .nav-item .dropdown-menu .dropdown-item {
      padding-left: 1.875rem
  }

  .resume-contact {
      border-left: 0
  }

  .mobile-is-hidden {
      display: none
  }
}

//test
.overview-section .item {
  margin-bottom: 2rem
}

.overview-section .item-desc {
  color: #4f4f4f;
  font-size: 0.875rem
}

.overview-section .item-icon {
  font-size: 1.875rem;
  color: #54B689
}

.overview-section .item-icon .fa-angular {
  color: #CA3327
}

.overview-section .item-icon .fa-js-square {
  color: #F1DE4F
}

.overview-section .item-icon .fa-react {
  color: #62D4FA
}

.overview-section .item-icon .fa-vuejs {
  color: #64B687
}

.overview-section .item-icon .nodejs {
  color: #55AC63
}

.overview-section .item-icon .fa-python {
  color: #456E9C
}

.overview-section .item-icon .fa-php {
  color: #777BAF
}

.overview-section .item-icon .fa-java {
  color: #2E68AB
}

.overview-section .item-icon .fa-html5,.overview-section .item-icon .fa-html5-alt {
  color: #DE6E3C
}

.overview-section .item-icon .fa-css3,.overview-section .item-icon .fa-css3-alt {
  color: #53A7DC
}

.overview-section .item-icon .fa-sass {
  color: #BF6B97
}

.overview-section .item-icon .fa-less {
  color: #324B7C
}

.overview-section .item-icon .fa-gulp {
  color: #C9514E
}

.overview-section .item-icon .fa-grunt {
  color: #EFAC42
}

.overview-section .item-icon .fa-npm {
  color: #BB443E
}

.overview-section .item-title {
  font-size: 1rem
}

.section-title {
  font-size: 2rem;
  position: relative;
  padding-left: 1.5rem
}

.section-title:before {
  content: "";
  display: inline-block;
  width: 5px;
  height: 100%;
  background: #3273dc;
  position: absolute;
  left: 0;
  top: 0
}

.blog-post-card {
  background: #fafafa;
  border: 1px solid rgba(0,0,0,0.025);
  height: 100%;
  border-radius: 0
}

.blog-post-card .card-title {
  font-size: 1.125rem
}

.blog-post-card .card-text,.blog-post-card .more-link {
  font-size: 0.875rem
}

.blog-post-card .card-footer {
  border-top: none;
  background: none;
  padding-top: 0
}

.name {
  font-size: 3rem
}

.blog-post-card {
  background: #fafafa;
  border: 1px solid rgba(0,0,0,0.025);
  height: 100%;
  border-radius: 0
}

.blog-post-card .card-title {
  font-size: 1.125rem
}

.blog-post-card .card-text,.blog-post-card .more-link {
  font-size: 0.875rem
}

.blog-post-card .card-footer {
  border-top: none;
  background: none;
  padding-top: 0
}

.blog-list .item .title {
  font-size: 1.275rem
}

.blog-list .item .title a {
  color: #292929
}

.blog-list .item .title a:hover {
  color: #292929
}

.blog-list .item .post-thumb {
  border-radius: 2px
}

.blog-list .item .intro {
  font-size: 0.875rem
}

.blog-list .item .more-link {
  font-size: 0.8125rem
}

@media (min-width: 992px) {
  .profile-teaser .profile-image {
      max-width:250px
  }

  .blog-list .post-thumb {
      max-width: 100%
  }
}

.blog-post-body div section ol li {
  margin-left: 1rem;
}
